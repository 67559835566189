.container {
    border: 2px dashed #bebebe;
    background: #e9e9e9;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.accept {
    border: 2px dashed green;
}

.reject {
    border: 2px dashed red;
}

.preview {
    display: inline-block;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;

    img {
        object-fit: cover;
        width: 120px;
        height: 120px;
        cursor: default;
    }
}

.close {
    position: absolute;
    top: -10px;
    right: -10px;
    background: black;
    color: white;
    border: 2px solid white;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
}

.title {
    position: absolute;
        top: 0px;
        background: black;
        left: 0px;
        color: white;
        padding: 5px;
        text-align: center;
        font-weight: bold;
        line-height: 20px;
        cursor: pointer;
}