.logo {
    float: left;
    width: 200px;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
}
.menu{
    float: right;
    flex-basis: 800px;
    // align-self: flex-end;
}

.editor {
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  