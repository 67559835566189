.root {
  position: relative;
}

.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  max-width: 100vh;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  pointer-events: auto;
  user-select: auto;
}

.hiddenModal {
  display: none;
  pointer-events: none;
  user-select: none;
}
.largeImageContainer {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
